<template>
  <div class="login d-flex flex-column b-card pa-4 pt-7 pb-9">
    <v-card-title>{{ $t('login.authorization') }}</v-card-title>
    <v-card-text>
      <Input
        width="400px"
        :placeholder="$t('login.login')"
        @change="email = $event"
        class="mb-4 pl-3"
        id="login"
      />
      <div class="d-flex flex-row align-center">
        <Input
          width="400px"
          :placeholder="$t('login.pass')"
          @change="password = $event"
          class="pl-3"
          id="password"
          type="password"
        />
      </div>

      <div class="d-flex flex-column align-center mt-7">
        <Button
          color="white"
          background-color="#64C7FF"
          @click="login"
          width="290px"
          :loading="isLoading"
          :disabled="isLoading"
          class="mb-3"
        >
          <template #content>
            {{ $t('login.enter') }}
          </template>
        </Button>
        <Button
          color="#64C7FF"
          background-color="white"
          @click="$router.push('registration')"
          :loading="isLoading"
          :disabled="isLoading"
          width="290px"
          border
        >
          <template #content>
            {{ $t('login.registration') }}
          </template>
        </Button>
        <div
          role="button"
          @click="$router.push('recovery-password')"
          class="links ml-1"
          style="margin-top: 10px;"
        >
          {{ $t('login.forgotYourPassword') }}
        </div>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import Input from '@/components/base/UI/Input'
import Button from '@/components/base/UI/Button'

export default {
  name: 'Login',
  components: { Input, Button },
  data () {
    return {
      isLoading: false,
      email: '',
      password: '',
      timeout: 1500
    }
  },
  methods: {
    async login () {
      if (!this.email || !this.password) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.$store.dispatch('systemMessages/info', {
          text: 'Введите данные.',
          time: 1500
        }), 350)
        return
      }
      this.isLoading = true
      await this.$store.dispatch('auth/login', { data: { email: this.email, password: this.password } })
      this.isLoading = false
    }
  }
}
</script>

<style scoped>

</style>
